/*!
Theme Name: Robbo Music
Author: Robert Pessagno
Version: 1.0
*/

//==============================
// Main
//==============================


// Global
//------------------------------
@import 'global/mixins';
@import 'global/fonts';
@import 'global/variables';
@import 'global/normalize';
@import 'global/global';
@import 'global/typography';


// Libraries
//------------------------------
@import 'lib/slick';
@import 'lib/icofont';


// Components
//------------------------------
@import 'components/forms';
@import 'components/buttons';
@import 'components/header';
@import 'components/footer';
@import 'components/hero';
@import 'components/banner';
@import 'components/intro';
@import 'components/player';
@import 'components/close-button';


// Templates
//------------------------------
@import 'templates/home';
@import 'templates/music';
@import 'templates/library';
@import 'templates/videos';
@import 'templates/photos';
@import 'templates/post';
@import 'templates/not-found';

// Print
//------------------------------
@import 'global/print';
