//==============================
// Hero
//==============================

.hero {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  &-content {
    padding: 10vw 7vw;
    width: 45%;

    @include bp(m) {
      width: 50%;
    }

    @include bp(s) {
      width: 100%;
      padding: 60px 30px;
    }
  }

  &-img {
    width: 55%;
    position: relative;

    @include bp(m) {
      width: 50%;
    }

    @include bp(s) {
      width: 100%;
      height: 70vw;
      order: -1;
    }

    img {
      @include centered;
      @include img-cover;
    }
  }

  &-group {
    padding-bottom: 120px;

    @include bp(s) {
      padding-bottom: 0;
    }

    .hero {
      padding-top: 120px;

      @include bp(s) {
        padding-top: 0;
      }

      &:nth-child(2n) {

        .hero-img {
          order: -1;
        }
      }
    }
  }
}


// can apply to .banner as well
.border {

  &-top {
    border-top: 1px solid #333;
  }

  &-bottom {
    border-bottom: 1px solid #333;
  }
}