//==============================
// Fonts
//==============================

@include font('DMSans', 'DMSans-Regular.ttf', 400, normal);
@include font('DMSans', 'DMSans-Italic.ttf', 400, italic);
@include font('DMSans', 'DMSans-Medium.ttf', 500, normal);
@include font('DMSans', 'DMSans-MediumItalic.ttf', 500, italic);
@include font('DMSans', 'DMSans-Bold.ttf', 700, normal);
@include font('DMSans', 'DMSans-BoldItalic.ttf', 700, italic);
@include font('DMSans', 'DMSans-Black.ttf', 800, normal);
@include font('DMSans', 'DMSans-BlackItalic.ttf', 800, italic);
