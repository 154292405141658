//==============================
// Header
//==============================

.header {
  position: fixed;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  height: 120px;
  padding: 0 30px;
  background-color: $beige;
  // background-color: darken($beige, 3%);
  transition: width $header-transition-time, background-color $header-transition-time, transform $header-transition-time, box-shadow $header-transition-time;

  @include bp(m) {
    height: 70px;
    padding: 0 20px 0 25px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: darken($beige, 10%) solid 1px;
  }

  &.open {

    .header-logo {

      .aaron-logo-white {
        fill: $charcoal;
      }
    }

    #menu-main-1 {

      .menu-item {
        color: $charcoal;

        &:after {
          border-color: $charcoal;
        }
      }
    }

    .header-shop-item {
      color: $charcoal;

      svg {
        fill: $blue;
      }
    }
  }

  &-logo {
    position: relative;
    z-index: 101;
    top: -2px;

    @include bp(m) {
      top: -2px;
    }

    img {
      display: block;
      height: 100px;
      width: auto;

      @include bp(m) {
        height: 55px;
      }

      .aaron-logo-white {
        transition: fill $header-transition-time;
      }
    }
  }

  &-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    // margin-left: 60px;
    flex-grow: 1;
    height: 100%;

    @include bp(l) {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      max-width: 500px;
      height: 100%;
      z-index: 100;
      margin-left: 0;
      padding: 60px 0 0 0;
      background-color: $beige;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      transform: translateX(100%);
      visibility: hidden;
      transition: opacity .5s, visibility .5s, transform .5s;
    }

    @include bp(s) {
      max-width: none;
    }

    &:after {

      @include bp(l) {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        border-left: 1px solid darken($beige, 10%);
      }

      @include bp(s) {
        display: none;
      }
    }

    &.open {

      @include bp(l) {
        visibility: visible;
        transform: translate(0);
      }
    }

    &-logo {
      // margin-right: 5px;
      margin-right: auto;

      a {
        display: block;

        img {
          width: 100px;
        }
      }
    }

    .menu-main-container {
      height: 100%;
      margin-left: auto;

      @include bp(l) {
        width: 100%;
        height: auto;
        margin-top: auto;
        padding-bottom: 40px;
      }

      #menu-main-1 {
        height: 100%;
      }

      ul {
        display: flex;
        flex-flow: row nowrap;
        height: 100%;

        @include bp(l) {
          flex-flow: column nowrap;
          align-items: center;
          height: auto;
        }

        li {
          @include meta;
          list-style-type: none;
          position: relative;
          color: $charcoal;

          @include bp(s) {
            font-size: 18px;
          }

          a {
            height: 100%;
            padding: 0 1em;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: relative;
            text-decoration: none;
            color: inherit;
            transition: color .2s;
            position: relative;
            z-index: 2;

            @include bp(l) {
              padding: .5em;
              font-size: 25px;
            }

            @include bp(xs) {
              font-size: 20px;
              padding: .3em;
            }

            &:after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              height: 0;
              width: 100%;
              background-color: $blue;
              z-index: 1;
              transition: height .1s;

              @include bp(l) {
                @include hide;
              }
            }

            &:hover,
            &[aria-current="page"] {

              &:after {
                height: 3px;
              }
            }
          }
        }
      }
    }

    // &-contact {
    //   margin: 0 20px 0 auto;

    //   @include bp(l) {
    //     width: 100%;
    //     margin: 20px 0;
    //     text-align: center;
    //   }

    //   a.button {
    //     margin: 0;
    //   }
    // }

    &-utility {
      display: flex;
      flex-flow: row nowrap;
      margin-left: 30px;

      @include bp(m) {
        margin-left: 0;
      }

      li {
        list-style-type: none;

        a {
          display: block;
          padding: 10px;
          transition: opacity .2s;

          &:hover {
            opacity: .7;
          }
        }

        img {
          display: block;
          height: 25px;
          width: auto;
        }
      }

      + .header-nav-utility {
        border-left: 1px solid #ccc;
        margin-left: 20px;
        padding-left: 20px;
      }
    }

    &-button {
      @include input-reset;
      width: 50px;
      height: 50px;
      padding: 0;
      position: relative;
      background-color: transparent !important;
      display: none;
      margin-left: auto;
      order: 1;
      z-index: 101;
      margin-left: auto;

      @include bp(l) {
        display: block;
      }

      span {
        display: block;
        width: 30px;
        height: 2px;
        background-color: $blue;
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 1;
        transition: transform $header-transition-time, opacity $header-transition-time, width $header-transition-time, background-color $header-transition-time;

        &.top {
          transform: translate(-50%, -7px) rotate(0deg);
          transform-origin: left top;
        }

        &.middle {
          transform: translate(-50%, 0) rotate(0deg);
        }

        &.bottom {
          transform-origin: left bottom;
          transform: translate(-50%, 7px) rotate(0deg);
        }
      }

      &.open {

        span {
          background: $blue;

          &.top {
            transform: translate(-35%, -8px) rotate(45deg);
          }

          &.middle {
            opacity: 0;
          }

          &.bottom {
            transform: translate(-35%, 12px) rotate(-45deg);
          }
        }
      }
    }
  }
}
