//==============================
// Intro
//==============================

.intro {
  text-align: center;
  padding: 100px 0 40px;
  width: calc(100% - 80px);
  max-width: 700px;
  margin: 0 auto;

  @include bp(m) {
    max-width: 600px;
    padding: 80px 0 40px;
  }

  @include bp(s) {
    padding: 60px 0 20px;
    max-width: 500px;
  }
}
