//==============================
// Music
//==============================

.music {
  // padding: 100px 0;

  &-items {
    // width: calc(100% - 80px);
    // max-width: 1600px;
    // margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
  }

  &-item {
    width: calc((100% / 4) - (#{$grid-gutter * 3} / 4));
    margin-right: $grid-gutter;
    margin-bottom: $grid-gutter*2;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;

    @include bp(l) {
      width: calc((100% / 3) - (#{$grid-gutter * 2} / 3));
    }


    @include bp(s) {
      width: 100%;
      margin-right: 0 !important;
      margin-bottom: $grid-gutter;
    }

    &:nth-child(4n) {
      margin-right: 0;

      @include bp(l) {
        margin-right: $grid-gutter;
      }
    }

    &:nth-child(3n) {

      @include bp(l) {
        margin-right: 0;
      }

      @include bp(s) {
        margin-right: $grid-gutter;
      }
    }

    &-img {
      width: 100%;
      position: relative;
      padding-top: 100%;
      margin-bottom: 20px;
      overflow: hidden;
      border-radius: 5px;

      img {
        @include img-cover;
        @include centered;
        position: absolute;
        width: 100%;
      }
    }

    &-list {
      position: relative;

      li {
        @include p1;
        line-height: 1.4;
        list-style-type: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 1.1em;
        padding: .3em .4em;
        transition: background-color .1s;

        &.active {
          background-color: darken($beige, 7%);
        }

        &:hover {
          background-color: darken($beige, 10%);
          cursor: pointer;
        }

        i {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background-color: $blue;
          // border: #aaa solid 1px;
          color: $beige;
          font-size: 8px;
          display: none;
        }

        span {
          width: calc(100% - 30px);
          padding: .1em 0 0 .5em;

          @include bp(s) {
            padding-top: 0;
          }
        }

        &.play {

          .icofont-ui-play {
            display: flex;
          }
        }

        &.pause {

          .icofont-ui-pause {
            display: flex;
          }
        }
      }
    }

    &-info {
      width: 100%;
      // width: calc(100% - 45px);
      // padding-left: 20px;
    }

    &-description {
      width: 100%;
      padding-top: 10px;
    }

    h2 {
      font-size: 20px;
      margin-left: 5px;
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: .01em;
      color: #999;
    }
  }
}