//==============================
// Post
//==============================

.post {
  padding: 100px 0;

  @include bp(s) {
    padding: 60px 0;
  }

  &-hero {
    position: relative;
    height: 40vw;

    @include bp(s) {
      height: 70vw;
    }

    img {
      @include centered;
      @include img-cover;
    }
  }

  &-header {
    margin-bottom: 40px;
  }

  &-wide {
    width: calc(100% - 80px);
    max-width: 1600px;
    margin: 0 auto;

    @include bp(s) {
      width: calc(100% - 60px);
    }
  }

  &-content {
    width: calc(100% - 80px);
    max-width: 900px;
    margin: 0 auto;

    @include bp(s) {
      width: calc(100% - 60px);
    }

    h1,
    h2 {
    }

    h3 {
    }

    h1,
    h2,
    h3 {

      + h1,
      + h2,
      + h3,
      + p,
      + ul,
      + ol {
        margin-top: .5em;
      }
    }

    p,
    ul,
    ol {
      @include p1;

      + p,
      + ul,
      + ol {
        margin-top: 1.5em;
      }

      + h1,
      + h2,
      + h3 {
        margin-top: 2em;
      }
    }

    ul,
    ol {
      margin-left: 2em;
    }

    li {

      + li {
        margin-top: .25em;
      }
    }

    img {
      display: block;
      margin: 1.5em 0;
      max-width: 100%;
      height: auto;
    }

    blockquote {
      margin: 0;
      padding: 0 0 3em 3em;
      position: relative;
      background: transparent url('assets/images/global/quote-marks.svg') left .4em / 1.6em auto no-repeat;

      p {
      }

      footer {
        margin-top: 1em;
        font-style: italic;
        color: $satan;
      }
    }

    a:not(.button) {
      color: currentColor;
      text-decoration: underline;
    }
  }
}
