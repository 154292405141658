//==============================
// Typography
//==============================

h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd {
  margin: 0;
  padding: 0;
}

@mixin h1 {
  font: normal 800 50px/1.1 $font-primary;
  margin-bottom: .3em;
  color: $blue;
  letter-spacing: -.04em;

  @include bp(l) {
    font-size: 44px;
  }

  @include bp(m) {
    font-size: 36px;
  }

  @include bp(s) {
    font-size: 30px;
  }
}

h1,
.h1 {
  @include h1;

  a {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

@mixin h2 {
  font: normal 800 40px/1.2 $font-primary;
  margin-bottom: .5em;
  color: $blue;
  letter-spacing: -.03em;

  @include bp(m) {
    font-size: 32px;
  }

  @include bp(s) {
    font-size: 26px;
  }
}

h2,
.h2 {
  @include h2;
}

@mixin h3 {
  font: normal 500 22px/1.6 $font-primary;

  @include bp(m) {
    font-size: 18px;
  }

  @include bp(s) {
    font-size: 16px;
  }
}

h3,
.h3 {
  @include h2;
}

@mixin p1 {
  font: normal 400 18px/1.8 $font-primary;
  color: $charcoal;

  @include bp(l) {
    font-size: 17px;
  }

  @include bp(m) {
    font-size: 16px;
    line-height: 1.6;
  }

  strong {
    font-weight: 600;
  }

  a:not(.button) {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }
}

p,
.p1 {
  @include p1;

  + p {
    margin-top: 1em;
  }

  + ul,
  + ol {
    margin-top: 2em;
  }

  + .button {
    margin-top: 2em;
  }

  &.small {
    font-size: 80%;
  }
}


@mixin p2 {
  font: normal 400 16px/1.6 $font-primary;

  @include bp(l) {
    font-size: 15px;
  }

  @include bp(s) {
    font-size: 14px;
  }
}

@mixin meta {
  font: 500 normal 17px/1.6 $font-primary;

  @include bp(m) {
    font-size: 16px;
  }
}
