//==============================
// Player
//==============================

.player {
  width: 100%;
  background-color: darken($beige, 15%);
  visibility: visible;
  transition: transform .5s, visibility .5s;
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  height: 180px;

  @include bp(s) {
    height: 160px;
  }

  &.hidden {
    transform: translateY(100%);
    visibility: hidden;
  }

  &-inner {
    width: 100%;
    max-width: 700px;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
  }

  &-title {
    @include h3;
    text-align: center;
    width: 100%;
    color: $charcoal;
    margin-bottom: 0;
  }

  &-artist {
    @include p2;
    text-align: center;
    width: 100%;
    margin-bottom: .5em;
    color: lighten($charcoal, 20%);
    margin: 0 0 1em 0;
  }

  &-control {
    width: 70px;
    height: 70px;
    position: relative;

    @include bp(s) {
      width: 50px;
      height: 50px;
    }

    &-button {
      @include centered;
      @include input-reset;
      background-color: transparent;
      cursor: pointer;

      &:active {
        outline: 0;

        i {
          transform: scale(.95);
        }
      }

      &.hidden {
        display: none;
        visibility: hidden;
        opacity: 0;
      }
    }

    i {
      display: block;
      font-size: 50px;
      color: #333;

      @include bp(s) {
        font-size: 40px;
      }
    }
  }

  &-waveform {
    width: calc(100% - 200px);
    height: 60px;
    margin-left: 15px;

    @include bp(s) {
      width: calc(100% - 170px);
    }
  }

  &-time {
    @include meta;
    text-align: right;
    width: 100px;
    margin-left: auto;
  }

  // &-buttons {
  //   width: 80px;
  //   margin-left: 20px;
  // }

  #waveform {

  }
}


// HTML Player
audio {
  width: 100%;
}

audio::-webkit-media-controls-panel {
  background-color: darken($beige, 20%);
  border-radius: 0;
}
