//==============================
// Forms
//==============================

@mixin input-reset {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
}

select {
  &::-ms-expand {
    display: none;
  }
}


input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="date"],
input[type="number"],
select,
textarea {
  @include input-reset;
  font: 400 normal 17px/1.7 $font-primary !important;
  color: $charcoal;
  width: 100% !important;
  background: $white;
  border: #aaa solid 1px;
  padding: .8em 1.3em !important;
  color: $charcoal !important;
  height: auto !important;

  @include bp(l) {
    font-size: 16px;
  }

  @include bp(s) {
    line-height: 1.8;
  }

  @include bp(s) {
    font-size: 15px;
  }
}

input[type="submit"],
button[type="submit"] {
  @include input-reset;
  // @include button;
}

select {
  padding-right: 2em !important;
  background: $white url('assets/images/global/arrow-down.svg') right center / 25px auto no-repeat;
}

textarea {
  resize: none;
  height: 170px !important;
}

.wpforms {

  &-field-container {
    display: flex;
    flex-flow: row wrap;
  }

  &-field {
    margin-bottom: $form-gutter !important;
    padding: 0 !important;

    &.one-half {
      width: calc(50% - (#{$form-gutter} / 2));
      margin-right: $form-gutter !important;

      &:nth-child(2n) {
        margin-right: 0 !important;
      }

      @include bp(s) {
        width: 100%;
        margin-right: 0 !important;
      }
    }

    &.full {
      width: 100% !important;
    }

    label.wpforms-error,
    em.wpforms-error {
      @include meta;
      margin-top: .5em !important;
    }
  }

  &-submit {
    font: 500 normal 17px/1.6 $font-primary !important;
    color: $beige !important;
    display: inline-block !important;
    text-decoration: none !important;
    position: relative !important;
    border: 0 !important;
    padding: .5em 1.8em !important;
    background-color: $blue !important;
    border-radius: 2em !important;
    height: auto !important;

    @include bp(m) {
      font-size: 16px !important;
    }

    &:hover,
    &:focus {
      background: darken($blue, 10%) !important;
      cursor: pointer !important;
    }

    &-container {
      text-align: center;
    }
  }
}

