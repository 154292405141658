//==============================
// Photos
//==============================

.photo {

  &-gallery {

    &-item {
      width: calc((100% / 2) - (10px * 1 / 2));
      float: left;
      margin-bottom: 10px;
      opacity: 0;

      @include bp(s) {
        width: 100%;
      }

      &.show {
        opacity: 1;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
