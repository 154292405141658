//==============================
// Library
//==============================

.library {
  // display: flex;
  // flex-flow: row wrap;

  // .post-header {
  //   width: 100%;
  // }

  &-genres {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;

    li {
      list-style-type: none;
      margin: 0 .5em .5em 0;

      button {
        @include input-reset;
        @include p1;
        color: $charcoal;
        line-height: 1.4;
        border-radius: 1.1em;
        padding: .3em 1em;
        transition: background-color .1s, color .1s;
        background-color: darken($beige, 7%);

        &:hover {
          background-color: darken($beige, 10%);
          cursor: pointer;
        }

        &.active {
          background-color: $blue;
          color: $beige;
          cursor: pointer;
        }
      }
    }
  }

  // &-genres {
  //   width: 300px;
  //   background-color: $beige-dark;
  //   padding: 30px 10px;
  //   border: $tan solid 1px;
  //   margin-right: auto;

  //   @include bp(m) {
  //     position: fixed;
  //     left: 10px;
  //     top: 10px;
  //     width: calc(100% - 20px);
  //     height: calc(100% - 20px);
  //     z-index: 1000;
  //     overflow-style: hidden;
  //     opacity: 0;
  //     visibility: hidden;
  //     transform: scale(.95);
  //     transition: opacity .5s, visibility .5s, transform .5s;
  //     box-shadow: 0 0 40px rgba(0,0,0,.5);
  //     padding-top: 60px;
  //   }

  //   &.open {

  //     @include bp(m) {
  //       opacity: 1;
  //       visibility: visible;
  //       transform: scale(1);
  //     }
  //   }

  //   .close-button {
  //     display: none;
  //     right: 15px;
  //     top: 10px;
  //     z-index: 2;

  //     &:before,
  //     &:after {
  //       border-bottom-color: $brown;
  //     }

  //     @include bp(m) {
  //       display: block;
  //     }
  //   }

  //   .xoxo {

  //     > li {
  //       list-style-type: none;
  //       border-bottom: #444 solid 1px;

  //       &:last-child {
  //         border-bottom-color: transparent;
  //       }

  //       > ul {

  //         li {
  //           margin-bottom: 8px;
  //           font-size: 14px;

  //           &.current-cat {
              
  //             a {
  //               text-decoration: underline;
  //             }
  //           }
  //         }

  //         a {
  //           text-decoration: none;
  //           color: $beige;

  //           &:before {
  //             margin-right: 7px;
  //           }

  //           &:hover {
  //             text-decoration: underline;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .product-categories {
  //     margin-bottom: 20px;

  //     li {
  //       list-style-type: none;
  //     }
  //   }


  //   .woocommerce-widget-layered-nav-list {
  //     height: 0;
  //     max-height: 210px;
  //     overflow: scroll;
  //     position: relative;

  //     &.open {
  //       height: auto;
  //       margin-bottom: 17px;
  //       padding-bottom: 20px;
  //     }
  //   }
  // }
  
  &-table {
    @include meta;
    line-height: 1.4;
    color: $charcoal;
    border-collapse: collapse;
    background: transparent;
    width: 100%;

    @include bp(m) {
      width: 100%;
    }

    tr {

      &:hover {
        background-color: $beige-dark;
      }

      &.hidden {
        @include hide;
      }
    }

    th {
      text-align: left;
      font-weight: 700;
      background-color: $beige-dark;
      // border-top: $tan solid 1px;
      border-bottom: $tan solid 1px;
      padding: 20px;
    }

    th,
    td {
      text-align: left;
      // vertical-align: top;
    }

    td {
      border-bottom: $tan solid 1px;
      padding: 30px 20px;

      @include bp(s) {
        padding: 20px;
      }
    }

    &-row {

      &-controls {
        width: 5%;
        padding-left: 10px !important;
        padding-right: 0 !important;
      }

      &-title {
        width: 13%;
      }

      &-genre {
        width: 12%;
        white-space: nowrap;

        @include bp(s) {
          white-space: normal;
        }
      }

      &-tags {
        width: 35%;
      }

      &-bpm {
        width: 5%;
      }

      &-key {
        width: 5%;
      }

      &-mood {
        width: 25%;
      }

      &-tags,
      &-bpm,
      &-key,
      &-mood {

        @include bp(s) {
          display: none;
        }
      }
    }

    &-button {
      @include input-reset;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 1.1em;
      transition: background-color .1s;

      &.active {

        i {
          background-color: darken($blue, 7%);
        }
      }

      &:hover {

        i {
          background-color: darken($blue, 10%);
          cursor: pointer;
        }
      }

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $blue;
        color: $beige;
        font-size: 10px;
        display: none;
      }

      &.play {

        .icofont-ui-play {
          display: flex;
        }
      }

      &.pause {

        .icofont-ui-pause {
          display: flex;
        }
      }
    }
  }
}

.page-template-page-library {

  .player-title {
    margin: .5em 0;
  }
}

