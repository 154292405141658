//==============================
// Banner
//==============================

.banner {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0 120px;
  position: relative;

  background-color: darken($beige, 7%);

  @include bp(s) {
    padding: 20px 0 55px;
  }

  // &:before {
  //   content: '';
  //   @include fillcontainer;
  //   background-color: $white;
  //   z-index: 1;
  //   opacity: .85;
  // }

  .intro {
    position: relative;
    z-index: 2;
  }
}
