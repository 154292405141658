//==============================
// Variables
//==============================


// Colors
//------------------------------

$selection: #b3d4fc;

$white: #fff;
$black: #000;
$charcoal: #111;
$beige: #f5f2ed;
$beige-dark: darken($beige, 5%);
$rust: #7a6a45;
$brown: #8b8158;
$blue: #31586b;
$red: #b53737;
$satan: #666;
$tan: #b7afa4;

$font-primary: 'DMSans';

$header-transition-time: .4s;

$grid-gutter: 40px;
$video-gutter: 20px;
$photo-gutter: 10px;
$form-gutter: 10px;