//==============================
// Home
//==============================

.home {

}

.testimonials {
  padding: 80px 0;
  background-color: darken($beige, 7%);

  @include bp(s) {
    padding: 60px 0;
  }

  &-intro {
    text-align: center;
    padding: 0 30px 20px;
  }

  &-items {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @include bp(l) {
      max-width: 900px;
    }
  }

  &-item {
    margin: 0;
    text-align: center;
    padding: 2em 0 0 0;
    background: transparent url('assets/images/global/quote-marks.svg') center 0 / 1.6em auto no-repeat;

    footer {
      margin-top: 1em;
      font-style: italic;
      color: $satan;
    }
  }

  .slick {

    &-arrow {
      overflow: hidden;
      text-indent: -9999px;
      @include input-reset;
      background-color: transparent;
      position: relative;
      width: 80px;
      padding: 0;
      margin: 0;
      cursor: pointer;

      @include bp(s) {
        width: 50px;
        @include hide;
      }

      &:before {
        content: '';
        position: absolute;
        background-color: $blue;
        width: 35px;
        height: 35px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;

        @include bp(s) {
          width: 30px;
          height: 30px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        border-top: $beige solid 2px;
        border-right: $beige solid 2px;
        width: 7px;
        height: 7px;
        top: 50%;
        left: 50%;

        @include bp(s) {
          width: 5px;
          height: 5px;
        }
      }
    }

    &-prev {
      order: 1;

      &:after {
        transform: translate(-35%, -50%) rotate(-135deg);
      }
    }

    &-next {
      order: 3;

      &:after {
        transform: translate(-72%, -50%) rotate(45deg);
      }
    }

    &-dots {
      order: 2;
    }

    &-list {
      order: 2;
      width: calc(100% - 160px);
      max-width: 700px;
      margin: 0 auto;

      @include bp(s) {
        width: calc(100% - 60px);
      }
    }

    &-dots {
      width: 100%;
      margin: 30px 0 0 0;
      padding: 10px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      z-index: 1;
      order: 4;

      li {
        width: 7px;
        height: 7px;
        background: none;
        list-style-type: none;
        margin: 0 3px;
        border-radius: 5px;
        background-color: darken($beige, 20%);
        // border: $blue solid 1px;
        cursor: pointer;
        transition: background .2s;

        &.slick-active {
          background: $blue;
        }

        button {
          display: none;
        }
      }
    }
  }
}

