//==============================
// Videos
//==============================

.video {

  &-gallery {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;

    &-item {
      width: calc(50% - (#{$grid-gutter} / 2));
      margin-right: $grid-gutter;
      margin-bottom: 60px;

      @include bp(s) {
        width: 100%;
        margin-bottom: 40px;
        margin-right: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:last-child {

        @include bp(s) {
          margin-bottom: 0;
        }
      }

      .fluid-width-video-wrapper {
        margin-bottom: 10px;
      }
    }
  }
}
