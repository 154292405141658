//==============================
// Buttons
//==============================

.button {
  @include meta;
  color: $beige;
  display: inline-block;
  text-decoration: none;
  position: relative;
  border: 0;
  padding: .5em 1.8em;
  background-color: $blue;
  border-radius: 2em;
  transition: background-color .2s;

  &:hover,
  &:focus {
    background: darken($blue, 10%);
    cursor: pointer;
  }
}