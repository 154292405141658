//==============================
// Close Button
//==============================

.close-button {
  @include input-reset;
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: transparent;
  transition: opacity .3s;
  top: 10px;
  right: 10px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    border-bottom: $charcoal solid 2px;
    transition: border-bottom-color .3s;

    @include bp(s) {
      width: 15px;
    }
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover,
  &:focus {
    cursor: pointer;
    // outline: 0;
  }
}
